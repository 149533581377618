import {StoreStateShape} from '@/redux/store/storeStateTypes';

export const leftNavbarSelector = ({
    commonUserDataReducer: {
        employment,
        permissions,
        leftNavbarOpen,
        features: {DisplayMissedCallsLog},
        user: {roles},
    },
    leftNavbarReducer: {
        logCount: {newCount, inReviewCount, highestLevelOfImpact},
    },
}: StoreStateShape) => ({
    newCount,
    permissions,
    inReviewCount,
    highestLevelOfImpact,
    nurseId: employment?.id,
    leftNavbarOpen,
    isMissedCallsLogAvailable: DisplayMissedCallsLog,
    userRoles: roles,
});
