import {Dispatch, SetStateAction} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import Grid from '@mui/material/Grid';

import HandleOutsideClick from '@/components/base/MHHandleOutsideClick';

import {isDisplayMissedCallsLogEnabledSelector, phoneCallDataSelector} from '../../redux/selectors';
import {AGENT_STATUSES} from '@/constants/constants';

import commonStyles from '../shared/common.module.scss';
import styles from './ccpControls.module.scss';
import awsConnectService from "@/services/awsConnectApiService";

type CallControlsProps = {
    ccpStatus: boolean;
    handleCCPStatus: Dispatch<SetStateAction<boolean>>;
};

export const CCPControls = ({ccpStatus, handleCCPStatus}: CallControlsProps) => {
    const {awsConnectAgentStatus, isOutboundCallActive} = useSelector(phoneCallDataSelector, shallowEqual);
    const {isDisplayMissedCallsLogEnabled} = useSelector(isDisplayMissedCallsLogEnabledSelector, shallowEqual);
    const {isCppAgentInitialized} = awsConnectService;

    const toggleCCPPanel = () => {
        handleCCPStatus((prevCPPStatus) => !prevCPPStatus);
    };
    const closeCCPPanel = () => {
        handleCCPStatus(false);
    };
    const showControls = isDisplayMissedCallsLogEnabled === true
        ? isCppAgentInitialized()
        : awsConnectAgentStatus !== AGENT_STATUSES.OFFLINE;

    return (
        <Grid
            className={`${commonStyles.gridBorder} ${isOutboundCallActive && styles.active} ${styles.cppPanelWrapper}`}
            item
        >
            {showControls && (
                <HandleOutsideClick action={closeCCPPanel} className={styles.controlsIconWrapper}>
                    <DialpadOutlinedIcon className={styles.controlsIcon} onClick={toggleCCPPanel} />
                </HandleOutsideClick>
            )}
            <div className={ccpStatus ? styles.cppPanelVisible : styles.cppPanelHidden}>
                <div className={styles.awsConnectContainer} id="awsConnectContainer" />
            </div>
        </Grid>
    );
};
