import {Dispatch, MouseEvent, SetStateAction, useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import {Grid, Popover} from '@mui/material';

import {MHButton, MHTypography} from '@/components/base';
import {AvailableCallTransferAgents} from '@/components/general/AvailableCallTransferAgents';
import routes from '@/constants/routes';
import awsConnectService from '@/services/awsConnectApiService';

import {phoneCallDataSelector} from '../../redux/selectors';
import {AGENT_STATUSES} from '@/constants/constants';

import commonStyles from '../shared/common.module.scss';
import styles from './callControls.module.scss';

export const CallControls = () => {
    const history = useHistory();
    const [activeAgentsIsOpen, setActiveAgentsIsOpen] = useState(false);
    const [activeAgentsAnchorElement, setActiveAgentsAnchorElement] = useState(null);

    const {isOutboundCallActive, isIncomingCall, callerPatientId, awsConnectAgentStatus} = useSelector(
        phoneCallDataSelector,
        shallowEqual
    );

    const toggleTransfersPopover = (event: Dispatch<SetStateAction<boolean>> | MouseEvent) => {
        // activeAgentsIsOpen can be either true or false
        const nextAnchorState = !activeAgentsIsOpen && 'currentTarget' in event ? event.currentTarget : null;
        setActiveAgentsIsOpen(!activeAgentsIsOpen);
        setActiveAgentsAnchorElement(nextAnchorState);
    };

    useEffect(() => {
        if (!isOutboundCallActive) setActiveAgentsIsOpen(false);
    }, [isOutboundCallActive, setActiveAgentsIsOpen]);

    const {disconnectContact} = awsConnectService;
    const isTransferCallAvailable = awsConnectAgentStatus === AGENT_STATUSES.BUSY;

    return (
        <>
            <div className={styles.incommingCallWrapper}>
                <PhoneIcon />
                <MHTypography variant="headline6">{isIncomingCall ? 'Incoming Call' : 'Outgoing Call'}</MHTypography>
            </div>
            <Grid className={`${commonStyles.gridBorder} ${isOutboundCallActive && commonStyles.active}`} item>
                {callerPatientId && (
                    <MHButton
                        buttonType="primaryThird"
                        className={styles.transferButton}
                        onClickHandler={() => history.push(routes.PATIENT_SINGLE_OVERVIEW(callerPatientId.toString()))}
                    >
                        <AccountCircleOutlinedIcon />
                        <span>Go to patient profile</span>
                    </MHButton>
                )}
                <MHButton
                    disabled={!isTransferCallAvailable}
                    buttonType="primary"
                    onClickHandler={toggleTransfersPopover}
                    className={styles.transferButton}
                >
                    <PhoneForwardedOutlinedIcon />
                    <span>Transfer</span>
                    <ArrowDropDownOutlinedIcon />
                </MHButton>
                <MHButton buttonType="primary" onClickHandler={disconnectContact} className={styles.endCallButton}>
                    <CallEndOutlinedIcon />
                    <span>End Call</span>
                </MHButton>
            </Grid>
            <Popover
                open={activeAgentsIsOpen}
                anchorEl={activeAgentsAnchorElement}
                classes={{root: styles.popover}}
                onClose={toggleTransfersPopover}
                anchorOrigin={{
                    vertical: 80,
                    horizontal: 0,
                }}
            >
                <AvailableCallTransferAgents {...{handlePopoverClose: toggleTransfersPopover}} />
            </Popover>
        </>
    );
};
