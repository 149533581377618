import {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

import awsConnectService, {initAwsConnect} from '@/services/awsConnectApiService';

import {
    employmentSelector,
    isDisplayMissedCallsLogEnabledSelector,
    patientIdSelector,
    phoneCallDataSelector
} from '../redux/selectors';
import {CallControls} from './CallControls';
import {CallToNumberInput} from './CallToNumberInput';
import {CCPControls} from './CCPControls';
import {AGENT_STATUSES} from '@/constants/constants';

export const CallHandler = () => {
    const {isOutboundCallActive, awsConnectAgentStatus, needToShowCPPPanel, isDoNotDisturbedEnabled} = useSelector(
        phoneCallDataSelector,
        shallowEqual
    );
    const {routingProfile} = useSelector(employmentSelector, shallowEqual);
    const patientId = useSelector(patientIdSelector, shallowEqual);
    const {isCppAgentInitialized} = awsConnectService;
    const {isDisplayMissedCallsLogEnabled} = useSelector(isDisplayMissedCallsLogEnabledSelector, shallowEqual);

    const isLargerScreen = useMediaQuery('(min-width:1150px)');

    const [showCPPPanel, setShowCPPPanel] = useState(false);

    const history = useHistory();

    useEffect(() => {
        routingProfile && initAwsConnect(history);
    }, [routingProfile, history]);

    useEffect(() => {
        awsConnectAgentStatus && setShowCPPPanel(false);
    }, [awsConnectAgentStatus, setShowCPPPanel]);

    useEffect(() => {
        if (needToShowCPPPanel) setShowCPPPanel(true);
    }, [needToShowCPPPanel, setShowCPPPanel]);

    const showInputNumber = isDisplayMissedCallsLogEnabled === true
        ? isCppAgentInitialized() && !isOutboundCallActive && (
            awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE || (awsConnectAgentStatus === AGENT_STATUSES.OFFLINE && isDoNotDisturbedEnabled))
        : awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE && !isOutboundCallActive;

    return (
        <>
            {isOutboundCallActive && <CallControls />}
            <CCPControls handleCCPStatus={setShowCPPPanel} ccpStatus={showCPPPanel} />
            {showInputNumber && isLargerScreen && (
                <CallToNumberInput
                    onOutboundCall={setShowCPPPanel}
                    outboundCallLogData={patientId ? {patient_context: patientId} : null}
                />
            )}
        </>
    );
};
