import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {NavLink} from 'react-router-dom';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ChromeReaderModeOutlined from '@mui/icons-material/ChromeReaderModeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EventIcon from '@mui/icons-material/Event';
import MissedVideoCallOutlinedIcon from '@mui/icons-material/MissedVideoCallOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import Drawer from '@mui/material/Drawer';
import classnames from 'classnames';

import {MHToolTip} from '@/components/base';
import MHErrorBoundary from '@/components/base/MHErrorBoundary';
import {PerformanceImprovementLogTabIcon} from '@/components/features/PerformanceImprovementLogTabIcon';
import {leftNavbarSelector} from '@/components/general/LeftNavbar/redux/selectors';
import {getPerformanceImprovementLogCounter} from '@/components/general/LeftNavbar/redux/slice';
import {MISSED_CALL_LOGS_AVAILABLE_ROLES} from '@/constants/constants';
import permissionsStatic from '@/constants/PERMISSIONS';
import routesConst from '@/constants/routes';
import {toggleLeftSidebar} from '@/redux/commonUserData/slice';
import {LocalStorageManager} from '@/services/localStorageManager';
import {Route} from '@/types/commonTypes';

import styles from './leftNavbar.module.scss';

type LeftNavbarProps = {
    routes: Route[];
};

const storage = new LocalStorageManager<{leftSidebarOpen: boolean}>('userSettings', 'v1.0');

export const LeftNavbar = ({routes}: LeftNavbarProps) => {
    const dispatch = useDispatch();
    const {
        newCount,
        permissions,
        inReviewCount,
        highestLevelOfImpact,
        nurseId,
        leftNavbarOpen,
        isMissedCallsLogAvailable,
        userRoles,
    } = useSelector(leftNavbarSelector, shallowEqual);
    const showImprovementLogIcon =
        permissions[permissionsStatic.CREATE_IMPROVEMENT_LOG] ||
        permissions[permissionsStatic.READ_REPORTED_IMPROVEMENT_LOG] ||
        permissions[permissionsStatic.MANAGE_REVIEW_IMPROVEMENT_LOG] ||
        permissions[permissionsStatic.READ_CLOSED_IMPROVEMENT_LOG];
    const patientMatch = useRouteMatch(routesConst.PATIENT_SINGLE());

    useEffect(() => {
        if (showImprovementLogIcon) {
            dispatch(getPerformanceImprovementLogCounter());
        }
    }, [dispatch, showImprovementLogIcon]);

    useEffect(() => {
        const leftSidebarOpen = !!storage.getData()?.leftSidebarOpen;
        dispatch(toggleLeftSidebar(leftSidebarOpen));
    }, [dispatch]);

    const filteredRoutes = routes.filter(({sub, isHidden}) => !sub && !isHidden);
    const hasReviewManagePermissions = permissions[permissionsStatic.MANAGE_REVIEW_IMPROVEMENT_LOG];

    const switchDrawer = (open: boolean) => {
        storage.setPartially({leftSidebarOpen: open});
        dispatch(toggleLeftSidebar(open));
    };

    const showMissedVideoCallIcon =
        !!isMissedCallsLogAvailable && MISSED_CALL_LOGS_AVAILABLE_ROLES.some((role) => userRoles?.includes(role));

    const menuIconsMapTop = {
        [`/my-page/${nurseId}`]: <DashboardOutlinedIcon />,
        '/patients': <SupervisorAccountOutlinedIcon />,
        '/action-queue': <ChromeReaderModeOutlined />,
        ...(showMissedVideoCallIcon
            ? {
                  '/call-bell-log': (
                      <MissedVideoCallOutlinedIcon sx={{fontSize: '32px !important', ml: '13px !important'}} />
                  ),
              }
            : {}),
        '/calendar': <EventIcon />,
        ...(showImprovementLogIcon
            ? {
                  '/performance-improvement-log': (
                      <PerformanceImprovementLogTabIcon
                          mainTab
                          highestLevelOfImpact={highestLevelOfImpact}
                          count={newCount + (hasReviewManagePermissions ? inReviewCount : 0)}
                      />
                  ),
              }
            : {}),
    };

    const menuIconsMapBot = {
        '/vendor-dictionary': <AccountTreeOutlinedIcon />,
        '/administrate': <SettingsIcon />,
    };

    const renderRoutes = (routes: Route[], place?: string) => {
        const menuIconsMap = place === 'bot' ? menuIconsMapBot : menuIconsMapTop;
        return routes.map(
            ({route, title}) =>
                route in menuIconsMap && (
                    <MHToolTip light placement="right" tooltipContent={title} key={route}>
                        <NavLink
                            className={styles.navbarLink}
                            exact={route === '/'}
                            to={route}
                            key={title}
                            activeClassName={styles.activeNavbarLink}
                            isActive={(match) => {
                                // Highlighting left bar patients menu when on patient pages
                                return (route === '/patients' && !!patientMatch) || !!match;
                            }}
                        >
                            <>
                                {menuIconsMap[route as keyof typeof menuIconsMap]}
                                <span>{title}</span>
                            </>
                        </NavLink>
                    </MHToolTip>
                ),
        );
    };

    return (
        <MHErrorBoundary>
            <Drawer
                variant="permanent"
                open={leftNavbarOpen}
                classes={{
                    root: classnames(styles.navbarRoot, {[styles.navbarHidden]: !leftNavbarOpen}),
                    paper: classnames(styles.navbarPaper, {[styles.navbarHidden]: !leftNavbarOpen}),
                }}
            >
                <div className={styles.navbarTop}>{renderRoutes(filteredRoutes)}</div>
                <div className={styles.navbarBot}>{renderRoutes(filteredRoutes, 'bot')}</div>

                <div
                    className={styles.navbarToggleButton}
                    onClick={() => {
                        switchDrawer(!leftNavbarOpen);
                    }}
                >
                    {leftNavbarOpen ? <ArrowBackOutlinedIcon /> : <ArrowForwardOutlinedIcon />}
                </div>
            </Drawer>
        </MHErrorBoundary>
    );
};
