import {shallowEqual, useSelector} from 'react-redux';

import {MHSwitch} from '@/components/base/MHSwitch';
import {LoggingActions} from '@/constants/loggingActions';
import awsConnectService from '@/services/awsConnectApiService';
import {loggerService} from '@/services/loggerService';

import {phoneCallDataSelector, userSelector} from '../redux/selectors';
import {AGENT_STATUSES} from '@/constants/constants';

import styles from './availabilityStatusSwitcher.module.scss';
import {store} from "@/redux/store";
import {setIsDoNotDisturbedEnabled} from "@/redux/commonUserData/slice";
import {useEffect} from "react";

export const AvailabilityStatusSwitcher = () => {
    const {awsConnectAgentStatus, isDoNotDisturbedEnabled} = useSelector(phoneCallDataSelector, shallowEqual);
    const {email} = useSelector(userSelector, shallowEqual);

    useEffect(() => {
        const syncWithLocalStorage = () => {
            const storedIsDndEnabled = localStorage.getItem('isDndEnabled') === 'true';
            if (isDoNotDisturbedEnabled !== storedIsDndEnabled) {
                store.dispatch(setIsDoNotDisturbedEnabled(storedIsDndEnabled));
            }
        };

        window.addEventListener('storage', syncWithLocalStorage);
        syncWithLocalStorage();

        return () => {
            window.removeEventListener('storage', syncWithLocalStorage);
        };
    }, []);

    const {goOffline, goAvailable} = awsConnectService;

    const changeUserState = () => {
        if (isAvailable) {
            goOffline(() => {
                window.awsConnectService.setStoreStatus('Offline');
                store.dispatch(setIsDoNotDisturbedEnabled(true));
            });
        } else {
            goAvailable(() => {
                window.awsConnectService.setStoreStatus('Available');
                store.dispatch(setIsDoNotDisturbedEnabled(false));
            });
        }

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.amazonConnectStateChange,
            message: `State switcher was changed to ${isAvailable ? 'Offline' : 'Available'} by user`,
        });
    };

    const isAvailable = awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE;
    const isOnCall =
        awsConnectAgentStatus === AGENT_STATUSES.BUSY || awsConnectAgentStatus === AGENT_STATUSES.ON_VIDEO_CALL;
    return (
        <div id="connectStatusSwitcher">
            <MHSwitch
                disabled={isOnCall}
                checked={isAvailable}
                onChange={changeUserState}
                label={awsConnectAgentStatus === 'Offline' && isDoNotDisturbedEnabled ? 'Do Not Disturb' : awsConnectAgentStatus}
                switchWrapperClassName={isOnCall || (awsConnectAgentStatus === 'Offline' && isDoNotDisturbedEnabled) ? styles.busySwitch : ''}
            />
        </div>
    );
};
