import 'amazon-connect-streams';

import get from 'lodash/get';

import {LoggingActions} from '@/constants/loggingActions';
import {hideCallNotification, showCallNotification} from '@/redux/commonCalls/slice';
import {
    acceptContactSuccess,
    disconnectContactSuccess,
    setAwsConnectAgentStatus,
    setConnectingPhoneInboundStatuses,
    setConnectingPhoneStatuses,
    setOutboundCallStatus,
    setQueueARN,
    setTransferCallStatus,
} from '@/redux/commonUserData/slice';
import {store} from '@/redux/store';
import audioServiceFactory from '@/services/createAudioNodes';
import {generalNotify} from '@/utils/notifyMessages';

import {loggerService} from './loggerService';
import {MEETING_ID_KEY, VIDEO_CALL_STATUS_URL_ID_KEY, videoProvider} from './videoService';

const AGENT_QUEUE_NAME = 'Agent Transfer';
let isLoggingOut = false;

window.myCPP = window.myCPP || {};

// these messages are not final and could change later.
// this service will be refactored in a different task
const AUTH_ERROR_MESSAGE =
    'Your browser is experiencing authentication issues, please reload the page or log in once again';
const ACCESS_ERROR_MESSAGE =
    'Your browser is experiencing authentication issues, please reload the page or log in once again';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

const missedStatusStates = [
    'Error',
    'BadAddressAgent',
    'BadAddressCustomer',
    'FailedConnectAgent',
    'FailedConnectCustomer',
    'MissedCallAgent',
    'MissedCallCustomer',
    'RealtimeCommunicationError',
];

const showError = (err, message, showInBackground = false, dismissAfter = 6000) => {
    let notificationMessage = message || DEFAULT_ERROR_MESSAGE;

    if (isLoggingOut) return;

    if (err) {
        try {
            const error = JSON.parse(err);
            notificationMessage =
                error.type === 'NetworkingError'
                    ? 'Your browser is currently experiencing connection issues. Please reload the page.'
                    : error.message || DEFAULT_ERROR_MESSAGE;
        } catch {
            // just as a precaution
            notificationMessage = DEFAULT_ERROR_MESSAGE;
        }
    }

    generalNotify({
        showInBackground,
        title: 'Error',
        message: notificationMessage,
        status: 'error',
        dismissAfter,
    });

    loggerService.logEvent({
        severity: 'Error',
        email: store.getState().commonUserDataReducer?.user?.email,
        action: LoggingActions.amazonConnectErrors,
        message: notificationMessage,
    });
};

const setLivenessShortPolling = (agent) => {
    let numberOfFails = 0;

    const handleCallbacks = (timeoutID) => {
        numberOfFails = 0;
        clearTimeout(timeoutID);
    };
    // Short polling function
    const runTheTest = (timeoutID) =>
        agent.getEndpoints(agent.getAllQueueARNs(), {
            success: () => handleCallbacks(timeoutID),
            failure: () => handleCallbacks(timeoutID),
        });

    // 15 seconds timeout
    const startMyTimeout = () =>
        setTimeout(() => {
            numberOfFails += 1;
            if (numberOfFails >= 3) {
                showError(
                    null,
                    'Your browser is currently experiencing connection issues. Please reload the page.',
                    true,
                );
            }
        }, 15000);

    // activate the logic
    setInterval(() => {
        // Doing a shortPolling to aws,
        // any response success or failure will reset numberOfFails to 0,
        // numberOfFails = 0  will mean that aws connection is in active state
        // also any response will kill the timeout since it is no longer needed
        let myTimeout = startMyTimeout();
        runTheTest(myTimeout);
    }, 30000);
};

const initAwsConnect = (history) => {
    if (!window.myCPP.agent) {
        let containerDiv = document.getElementById('awsConnectContainer');
        let ccpUrl = `${window.AWS_CONNECT_URL}ccp-v2/`;
        window.connect.core.initCCP(containerDiv, {
            ccpUrl,
            loginUrl: window.AWS_CONNECT_LOGIN_URL,
            loginPopup: true,
            softphone: {
                disableRingtone: false,
                allowFramedSoftphone: true,
            },
        });

        window.connect.contact(window.awsConnectService.subscribeToContactEvents(history));
        window.connect.agent(window.awsConnectService.subscribeToAgentEvents(history));

        window.connect.core.onAuthFail(() => showError(null, AUTH_ERROR_MESSAGE, true, 0));
        window.connect.core.onAccessDenied(() => showError(null, ACCESS_ERROR_MESSAGE, true, 0));
    }
};

const outboundCall = (phoneNumber, outboundCallLogData) => {
    window.connect.agent(function (agent) {
        // +16317918378 VOIP Test service
        // +12138949968 AWS Connect number
        // https://testcall.com/804-222-1111/ test with digit numbers
        let endpoint = window.connect.Endpoint.byPhoneNumber(phoneNumber);
        agent.connect(endpoint, {
            success: function () {
                if (outboundCallLogData) {
                    //There is no possibility to properly track if contactId already exists when agent.connect success has been triggered
                    // but there is a small gap in time before it really exists
                    setTimeout(() => {
                        const contact = agent.getContacts()[0];

                        if (contact) {
                            loggerService.logDataFromSource(contact.contactId, outboundCallLogData);
                        }
                    }, 1000);
                }
                console.info('Success call!!!!!!');
            },
            failure: showError,
        });
    });
};

function isOutboundCallActive(status) {
    const userIsBusy = status === 'Busy';
    if (userIsBusy) {
        store.dispatch(hideCallNotification('awsCall'));
    }
    if (status === 'CallingCustomer' || userIsBusy) {
        store.dispatch(setOutboundCallStatus({isOutboundCallActive: true}));
    } else {
        store.dispatch(hideCallNotification('awsCall'));
        store.dispatch(setOutboundCallStatus({isOutboundCallActive: false, showAnswerButton: false}));
    }
}

const awsConnectService = {
    // AGENT
    subscribeToAgentEvents: () => (agent) => {
        window.myCPP.agent = agent;
        let agentInitialState = window.myCPP.agent.getState();

        const meetingId = sessionStorage.getItem(MEETING_ID_KEY);
        const videoStatusUrl = sessionStorage.getItem(VIDEO_CALL_STATUS_URL_ID_KEY);

        if (meetingId) {
            window.awsConnectService.onAVideoCall();
            videoStatusUrl && videoProvider.handleAwsStatus({meetingId, statusUrl: videoStatusUrl});
        } else if (agentInitialState.name === 'Offline') {
            window.awsConnectService.goAvailable();
            store.dispatch(setAwsConnectAgentStatus(agentInitialState.name));
        } else {
            store.dispatch(setAwsConnectAgentStatus(agentInitialState.name));
        }

        loggerService.logEvent({
            severity: 'Info',
            email: store.getState().commonUserDataReducer?.user?.email,
            action: LoggingActions.amazonConnectInitialization,
            message: 'Amazon Connect successfully initialized',
        });

        window.AWS_CONNECT_LIVENESS === 'true' && setLivenessShortPolling(agent);

        agent.onStateChange((agentStateChange) => {
            if (missedStatusStates.includes(agentStateChange.newState)) {
                setTimeout(() => {
                    const state = window.myCPP.agent.getState();
                    if (missedStatusStates.includes(state.name)) window.awsConnectService.goAvailable();
                }, 30000);
            }
            store.dispatch(setAwsConnectAgentStatus(agentStateChange.newState));
            isOutboundCallActive(agentStateChange.newState);

            loggerService.logEvent({
                severity: 'Info',
                email: store.getState().commonUserDataReducer?.user?.email,
                action: LoggingActions.amazonConnectStateChange,
                message: `Amazon Connect State was changed to ${agentStateChange.newState}`,
            });
        });

        agent.getEndpoints(agent.getAllQueueARNs(), {
            success(data = {}) {
                if (data.endpoints && data.endpoints.length) {
                    store.dispatch(setQueueARN(data.endpoints.filter(({type}) => type === 'queue')));
                }
            },
            failure(error) {
                showError(error);
            },
        });

        // fix automatically opens new window with CCP Iframe
        const w = window.open('', window.connect.MasterTopics.LOGIN_POPUP);
        if (w) {
            w.close();
        }
    },

    // CONTACT
    subscribeToContactEvents: () => (contact) => {
        window.myCPP.contact = contact;
        // window.myCPP.contact.getStatusDuration();

        // On Inbound Call
        contact.onConnecting(function () {
            if (contact.isInbound()) {
                const {stopNodes, playNodes, createAudioNodes} = audioServiceFactory();
                const incomingPhoneNumber = contact.getActiveInitialConnection().getEndpoint().phoneNumber;

                const contactAttributes = contact.getAttributes();

                const isPERS = get(contactAttributes, 'PERSHelpButton.value', 'false');
                createAudioNodes(
                    () => {
                        store.dispatch(
                            setConnectingPhoneInboundStatuses({
                                isOutboundCallActive: true,
                                showAnswerButton: true,
                                initialContactId: contact.getOriginalContactId() || contact.contactId,
                                sourceAgentEmail: get(contactAttributes, 'nurseId.value', ''),
                                isIncomingCall: true,
                                callerPatientId: get(contactAttributes, 'patientId.value', null),
                            }),
                        );
                        let firstName = get(contactAttributes, 'patientFirstName.value', '');
                        let lastName = get(contactAttributes, 'patientLastName.value', '');
                        let phoneType = get(contactAttributes, 'patientPhoneType.value', '');
                        let directRoutingLabel = get(contactAttributes, 'DirectRoutingLabel.value', '');
                        let patientSponsor = get(contactAttributes, 'patientSponsor.value', '');
                        let nurseFirstName = get(contactAttributes, 'nurseFirstName.value', '');
                        let nurseLastName = get(contactAttributes, 'nurseLastName.value', '');
                        let patientState = get(contactAttributes, 'patientState.value', '');

                        const callFromHelpButton = isPERS === 'true';
                        const patientData = {
                            patientSponsor,
                            nurseFirstName,
                            nurseLastName,
                            patientState,
                            firstName: directRoutingLabel || firstName,
                            lastName: directRoutingLabel ? '' : lastName,
                            phoneType,
                        };

                        store.dispatch(
                            showCallNotification({
                                ...patientData,
                                incomingPhoneNumber,
                                callFromHelpButton,
                                meetingId: 'awsCall',
                                mountCb: playNodes,
                                unmountCb: stopNodes,
                            }),
                        );
                    },
                    {isPERS},
                );

                const notificationManager = window.connect.core.getNotificationManager();

                notificationManager.requestPermission();
                notificationManager.show('Call from' + incomingPhoneNumber, {
                    body: 'You have an inbound call in Cesia. Click here to answer.',
                    clicked: function () {
                        window.focus();
                        this.close();
                    },
                });
            } else {
                store.dispatch(
                    setConnectingPhoneStatuses({
                        initialContactId: contact.getOriginalContactId() || contact.contactId,
                        isIncomingCall: false,
                    }),
                );
            }
        });
        contact.onRefresh((contact) => {
            window.awsConnectService.contactStatus = contact.getStatus().type;
        });
    },

    goAvailable: (
        success = () => {
            window.awsConnectService.setStoreStatus('Available');
        },
    ) => {
        if (window.DISABLE_AWS_LOGIN !== 'true') {
            window.connect.agent((agent) => {
                const [routableState] = agent.getAgentStates().filter(function (state) {
                    return state.type === window.connect.AgentStateType.ROUTABLE;
                });

                agent.setState(routableState, {
                    success,
                    failure: showError,
                });
            });
        } else {
            success();
        }
    },

    goOffline: (
        success = () => {
            window.awsConnectService.setStoreStatus('Offline');
        },
        loggingOut = false,
    ) => {
        if (loggingOut) {
            isLoggingOut = true;
        }

        if (window.myCPP.agent && window.DISABLE_AWS_LOGIN !== 'true') {
            let offlineState = window.myCPP.agent.getAgentStates().filter(function (state) {
                return state.type === window.connect.AgentStateType.OFFLINE;
            })[0];
            window.myCPP.agent.setState(offlineState, {
                success,
                failure: showError,
            });
        } else {
            success();
        }
    },

    onAVideoCall: (
        success = () => {
            window.awsConnectService.setStoreStatus('On a Video Call');
        },
    ) => {
        if (window.myCPP.agent && window.DISABLE_AWS_LOGIN !== 'true') {
            let onAVideoCall = window.myCPP.agent.getAgentStates().filter(function (state) {
                return state.name === 'On a Video Call';
            })[0];
            window.myCPP.agent.setState(onAVideoCall, {
                success,
                failure: showError,
            });
        } else {
            success();
        }
    },

    isCppAgentInitialized: () => {
        return window.myCPP.agent != null;
    },

    setStoreStatus: (status) => {
        store.dispatch(setAwsConnectAgentStatus(status));
    },

    acceptContact: (callback) => {
        window.myCPP.contact.accept({
            success: function () {
                store.dispatch(acceptContactSuccess(false));
            },
            failure: (err, message, showInBackground) => {
                showError(err, message, showInBackground);
                callback && callback();
            },
        });
    },

    disconnectContact: (callback) => {
        const agentConnection = window.myCPP?.contact?.getAgentConnection();
        if (agentConnection) {
            agentConnection.destroy({
                success: function () {
                    store.dispatch(disconnectContactSuccess({needToShowCPPPanel: false, callerPatientId: null}));

                    callback && callback();
                },
                failure: (err, message, showInBackground) => {
                    showError(err, message, showInBackground);
                    callback && callback();
                },
            });
        }
    },

    checkIfConnectionExists: () => {
        try {
            return window.myCPP.contact && window.myCPP.contact.getAgentConnection();
        } catch (error) {
            return null;
        }
    },
    /**
     * https://aws.amazon.com/ru/blogs/contact-center/making-cold-transfers-using-the-amazon-connect-streams-api/
     */
    _transferCall: (queue) => {
        if (queue) {
            const {agent, contact} = window.myCPP;
            const endpoint = new window.connect.Endpoint(queue);

            if (agent && contact) {
                contact.addConnection(endpoint, {
                    success() {
                        console.info('Successfully connected to queue');
                        store.dispatch(setTransferCallStatus(true));
                    },
                    failure(error) {
                        showError(error);
                    },
                });
            }
        } else {
            console.error("Queue wasn't found.");
        }
    },

    /**
     * If Amazon Connect call should be redirected to particular user,
     * we do POST request to mh-gateway with transfer data, it removes all users
     * from Agent Transfer queue and adds user to be transfered. Then we
     * need to create new endpoint and new contact from the agent.
     */
    transferToAgentQueue: () => {
        const {queueARN} = store.getState().commonUserDataReducer.phoneCallData;
        const agentTransferQueue = queueARN.find(({name}) => name === AGENT_QUEUE_NAME);

        window.awsConnectService._transferCall(agentTransferQueue);
    },

    transferToQueue: (queue) => {
        window.awsConnectService._transferCall(queue);
    },

    awsConnectLogout: (onFrameLoad) => {
        const iframe = document.createElement('iframe');
        const container = document.getElementById('awsConnectContainer');
        iframe.src = `${window.AWS_CONNECT_URL}logout`;
        iframe.setAttribute('style', 'visibility: hidden;');
        iframe.onload = () => {
            onFrameLoad && onFrameLoad();
            window.connect.core.terminate();
            container?.querySelector('iframe')?.remove();
            iframe.remove();
            window.myCPP = {};
        };
        window.awsConnectService.goOffline(() => {
            document.body.appendChild(iframe);
        }, true);
    },
};

window.awsConnectService = awsConnectService;

export default awsConnectService;

export {ACCESS_ERROR_MESSAGE, AUTH_ERROR_MESSAGE, DEFAULT_ERROR_MESSAGE, initAwsConnect, outboundCall};
